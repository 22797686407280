import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { OktaAuthStateService } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { cloneDeep } from 'lodash-es';
import { Subscription } from 'rxjs';
import { filter, mergeMap, skipWhile, take } from 'rxjs/operators';
import { FaviconService } from './services/favicon.service';
import { GTMService } from './services/gtm.service';
import { LoginService } from './services/login/login.service';
import { OptionsService } from './services/options.service';
import { TenantService } from './services/tenant.service';
import { ThemeService } from './services/theme.service';
import { UserDataService } from './services/userData.service';
import { SetApplicationData } from './store/actions/applicationData.action';
import { FetchV2FiltersFromApi } from './store/actions/filtersData.action';
import { ToggleFilterPanel } from './store/actions/menus.action';
import { FetchMLS } from './store/actions/mls.action';
import { FetchUserDefaultImage, FetchUserV2Data } from './store/actions/userData.action';
import { selectBrandCode } from './store/selectors/applicationData.selector';
import { selectRouteData } from './store/selectors/router.selector';
import { selectUser, selectUserDataLoaded } from './store/selectors/userData.selector';
import { wootricReady, wootricUser } from './store/selectors/wootric.selector';
import { IAppState } from './store/state/app.state';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  subs = new Subscription();
  brandCode: string;
  isSIR: boolean;
  isProd = false;
  isAuthenticated = false;
  personId: string;

  constructor(
    private _gtm: GTMService,
    private oktaAuthState: OktaAuthStateService,
    private loginService: LoginService,
    private userDataService: UserDataService,
    private optionService: OptionsService,
    private breakpointObserver: BreakpointObserver,
    private store: Store<IAppState>,
    private tenantService: TenantService,
    private faviconService: FaviconService,
    private themeService: ThemeService,
    private oktaAuth: OktaAuth
  ) {
    // Service Now is not set up for preprod, so it'll always redirect you to authenticate in preprod but we are not able to.
    this.isProd = environment.environment === 'prod';
  }

  checkPropExists(obj: Object, propKey: string) {
    return obj && obj.hasOwnProperty(propKey);
  }

  ngOnInit() {
    this.faviconService.useFavicon(this.tenantService.getTenant());

    if (this.optionService.verifyBrandInfoCache()) {
      this.store.dispatch(new SetApplicationData(this.optionService.brandInfo));
    } else {
      this.optionService.getBrandingFromApi().subscribe(data => {
        this.optionService.syncBranding(data);
        this.store.dispatch(new SetApplicationData(data));
      });
    }

    this.oktaAuthState.authState$.pipe(skipWhile(v => !v.isAuthenticated)).subscribe(state => {
      if (state.isAuthenticated) {
        this.oktaAuth.getUser().then(data => {
          if (data) {
            this.isAuthenticated = true;
            this.store.dispatch(new FetchUserV2Data(data.sub));
          }
        });
      } else {
        this.loginService.login();
      }
    });

    this.subs.add(
      this.store
        .select(selectUserDataLoaded)
        .pipe(
          filter(v => v),
          mergeMap(() => {
            return this.store.select(selectUser);
          }),
          take(1)
        )
        .subscribe(data => {
          this.personId = data.personMasterId;
          this.store.dispatch(new FetchUserDefaultImage());
          this.store.dispatch(new FetchV2FiltersFromApi());
          this.store.dispatch(new FetchMLS());
          this.store
            .select(wootricReady)
            .pipe(
              filter(wootricIsReady => wootricIsReady),
              mergeMap(() => {
                return this.store.select(wootricUser);
              }),
              take(1)
            )
            .subscribe(v => {
              this.store
                .select(selectBrandCode)
                .pipe(
                  filter(value => {
                    return !!value;
                  })
                )
                .subscribe(brandCode => {
                  this.brandCode = brandCode;
                  const dataObj = cloneDeep(v);
                  dataObj.createdAt = String(new Date(v.createdAt).getTime() / 1000);
                  dataObj['brand'] = this.brandCode;
                  dataObj['businessUnit'] = this.userDataService.getBusinessUnit();
                  dataObj['personMasterId'] = this.personId;
                  this._gtm.updateCustomSet(dataObj);
                  this.isSIR = this.themeService.isSIR();
                });
            });
        })
    );

    this.subs.add(
      this.store
        .select(selectRouteData)
        .pipe(filter(v => v !== undefined))
        .subscribe(d => {
          if (this.checkPropExists(d, 'public') && !d.public) {
            this.userDataService.getUserMlsData();
          }
        })
    );

    this.subs.add(
      this.breakpointObserver.observe('(min-width: 960px)').subscribe(() => {
        this.store.dispatch(new ToggleFilterPanel(false));
      })
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
