import { Injectable } from '@angular/core';
import { DataLayerObject } from '../gtm/data-layer-object';
import { DataLayerSet } from '../gtm/data-layer-set';

@Injectable()
export class DataLayerObjectService {
  private currentSet: DataLayerSet;

  constructor() {
    this._generateEmptySet();
  }

  set customSet(someSet: any) {
    Object.keys(DataLayerObject)
      .map(key => DataLayerObject[key])
      .forEach(key => {
        this.currentSet[key] = someSet[key] || this.currentSet[key];
      });
  }

  trigger() {
    (window as any).dataLayer.push(this.currentSet);
  }

  private _generateEmptySet() {
    this.currentSet = {
      [DataLayerObject.metro]: undefined,
      [DataLayerObject.office]: undefined,
      [DataLayerObject.metroName]: undefined,
      [DataLayerObject.metroID]: undefined,
      [DataLayerObject.officeName]: undefined,
      [DataLayerObject.officeID]: undefined,
      [DataLayerObject.officeLocationID]: undefined,
      [DataLayerObject.oktaID]: undefined,
      [DataLayerObject.userType]: undefined,
      [DataLayerObject.emailAddress]: undefined,
      [DataLayerObject.createdAt]: undefined,
      [DataLayerObject.titles]: undefined,
      [DataLayerObject.brand]: undefined,
      [DataLayerObject.businessUnit]: undefined,
      [DataLayerObject.personMasterId]: undefined
    };
  }
}
