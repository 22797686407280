import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MqOktaAuthService } from 'app/services/mq-okta-auth.service';
import { environment } from 'environments/environment';

declare const ServiceNowChat: any;

@Component({
  selector: 'app-service-now-chat-bubble',
  templateUrl: './service-now-chat-bubble.component.html',
  styleUrls: ['./service-now-chat-bubble.component.scss']
})
export class ServiceNowChatBubbleComponent implements OnInit, AfterViewInit {
  isProd = environment.environment === 'prod';
  constructor(private authService: MqOktaAuthService) {}

  ngOnInit(): void {
    if (this.isProd) {
      if (!this.authService.getServiceNowAuthSession()) {
        this.authService.setServiceNowAuthSession(true);
        window.location.href =
          'https://snprodsupport.marketquest.realogy.com/sn_va_web_client_login.do?sysparm_redirect_uri=' +
          encodeURIComponent(
            window.location.href.charAt(window.location.href.length - 1) === '/'
              ? window.location.href.substr(0, window.location.href.length - 1)
              : window.location.href
          );
      }
      this.initServiceNowWidget();
    }
  }

  ngAfterViewInit(): void {
    if (this.isProd) {
      this.addSSOIframeConfig();
    }
  }

  initServiceNowWidget(): void {
    if (ServiceNowChat) {
      new ServiceNowChat({
        instance: 'https://snprodsupport.marketquest.realogy.com',
        context: {
          skip_load_history: true,
          branding_key: 'MarketQuest_external_va'
        },
        branding: {
          bgColor: '#1F69FF',
          primaryColor: '#1F69FF',
          hoverColor: '#425989',
          activeColor: '#AAA',
          openIcon: 'omni_close_new.svg',
          closeIcon: 'omni_open_new.svg',
          sizeMultiplier: 1.6
        },
        offsetX: 25,
        offsetY: 25,
        position: 'right',
        translations: {
          'Open dialog': 'Open chat',
          'Open Chat. {0} unread message(s)': 'Click to open',
          'Close chat.': 'Click to close'
        }
      });
    }
  }

  addSSOIframeConfig(): void {
    window.addEventListener('message', e => {
      //redirect to SSO login if the web client logs in but is logged in as a guest user(unauthenticated)
      console.log(e.data.type, e.data.authenticated);
      if (e.data.type === 'SESSION_LOGGED_OUT' || (e.data.type === 'SESSION_CREATED' && e.data.authenticated === false)) {
        this.authService.setServiceNowAuthSession(true);
        window.location.href =
          'https://snprodsupport.marketquest.realogy.com/sn_va_web_client_login.do?sysparm_redirect_uri=' +
          encodeURIComponent(
            window.location.href.charAt(window.location.href.length - 1) === '/'
              ? window.location.href.substr(0, window.location.href.length - 1)
              : window.location.href
          );
      }
    });
  }
}
